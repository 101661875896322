import React, { useState } from 'react'
import type { ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form'
import { components as selectComponents, type MenuProps, type SelectInstance } from 'react-select'
import clsx from 'clsx'

import AsyncCreatableSelect from 'react-select/async-creatable'
import ReactAsyncSelect from 'react-select/async'
import { scopedTranslation } from '@utils/I18n'
const tShared = scopedTranslation('shared')

interface SelectOption {
  value: string
  label: string
}

type AsyncProps<Option = unknown, IsMulti extends boolean = true> = React.ComponentProps<
  typeof ReactAsyncSelect<Option, IsMulti>
>

type AsyncSelectProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  displayName: string
  placeholder?: string
  hideLabel?: boolean
  defaultOptions?: SelectOption[] | string[] | true
  defaultValue?: SelectOption | SelectOption[]
  components?: AsyncProps['components']
  loadOptions?: (inputValue: string) => Promise<SelectOption[]>
  field: ControllerRenderProps<TFieldValues, TName>
  totalCount?: number
  noOptionsMessage?: string
  isSearchable?: boolean
  isMulti?: boolean
  isCreatable?: boolean
  createOptionPrefix?: string
  dataTestId?: string
  disabled?: boolean
}

const AsyncSelect = React.forwardRef<SelectInstance, AsyncSelectProps<any, any>>(function AsyncSelect(
  {
    displayName,
    placeholder,
    hideLabel,
    defaultOptions,
    defaultValue,
    loadOptions,
    components = {},
    field,
    totalCount,
    noOptionsMessage,
    isCreatable = true,
    isSearchable = true,
    isMulti = true,
    createOptionPrefix = tShared('buttons.create'),
    dataTestId,
    disabled = false,
  },
  ref
) {
  const Menu = ({ children, ...props }: MenuProps<any>) => {
    // we have one Create new option which shouldn't be counted in the count
    const selectableOption = props.options.filter((option) => !option.label.includes('Create'))
    const optionsLength = selectableOption.length

    return (
      <selectComponents.Menu {...props}>
        <div className="tw-flex tw-flex-col tw-h-full ">
          {children}
          {!!totalCount && totalCount > 0 && optionsLength > 0 && (
            <div className="tw-w-full tw-py-2 tw-text-center tw-bg-primary-50 tw-text-primary-600">
              <p>
                {tShared('placeholders.dropdown_search_results', {
                  name: displayName.toLowerCase(),
                  optionsLength,
                  totalCount,
                })}
              </p>
              {totalCount > optionsLength && (
                <p className="tw-text-sm">{tShared('placeholders.dropdown_search_results_more_results')}</p>
              )}
            </div>
          )}
        </div>
      </selectComponents.Menu>
    )
  }

  if (isSearchable) {
    components.Menu = Menu
  }

  const labelClassName = hideLabel ? 'sr-only' : 'tw-mb-0 tw-pb-1 tw-text-gray-700 tw-font-medium'

  const [isFocused, setIsFocused] = useState(false)

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleUnfocus = () => {
    setIsFocused(false)
  }

  // This is a workaround for a bug caused by an interaction between react select and radix UI dialog
  // This causes focus to not be able to leave the select component when it's in a dialog
  // See: https://github.com/JedWatson/react-select/issues/5732
  const onBlurWorkaround = (event: React.FocusEvent<HTMLInputElement>) => {
    const element = event.relatedTarget
    if (element && (element.tagName === 'A' || element.tagName === 'BUTTON' || element.tagName === 'INPUT')) {
      ;(element as HTMLElement).focus()
    }
  }

  const handleBlur = (e) => {
    field.onBlur()
    handleUnfocus()
    onBlurWorkaround(e)
  }

  const customClassNames = {
    control: () => 'tw-h-full tw-border tw-rounded-lg tw-border-gray-300 tw-font-normal tw-text-base',
    menuList: () => 'tw-max-h-full',
    container: () =>
      clsx('tw-h-full', isFocused ? 'tw-border-primary-500 tw-rounded-lg tw-ring-4 tw-ring-primary-300' : ''),
  }

  const multiValueFnc = () => {
    return field.value?.map((value: string) => {
      return value
    })
  }

  const multiOnChange = (value: SelectOption[]) => {
    field.onChange(value.map((option) => option))
  }

  const AsyncComponent = isCreatable ? AsyncCreatableSelect : ReactAsyncSelect

  const createProps: any = {}
  if (isCreatable) {
    createProps.formatCreateLabel = (value) => `${createOptionPrefix} "${value}"`
  }

  return (
    <>
      <label htmlFor={field.name} className={labelClassName} id={`${field.name}.label`} data-testid={dataTestId}>
        {displayName}
      </label>
      <AsyncComponent
        isDisabled={disabled}
        components={components}
        className="tw-w-full"
        isSearchable={isSearchable}
        isMulti={isMulti}
        noOptionsMessage={() =>
          noOptionsMessage ?? tShared('placeholders.placeholder_search', { name: displayName.toLowerCase() })
        }
        onBlur={handleBlur}
        onFocus={handleFocus}
        loadingMessage={() => `${tShared('labels.searching')}...`}
        placeholder={placeholder ?? tShared('placeholders.placeholder_add', { name: displayName.toLowerCase() })}
        defaultOptions={defaultOptions}
        defaultValue={defaultValue}
        loadOptions={loadOptions}
        onChange={
          isMulti
            ? multiOnChange
            : (value: SelectOption) => {
                field.onChange(value)
              }
        }
        classNames={customClassNames}
        aria-labelledby={`${field.name}.label`}
        value={isMulti ? multiValueFnc() : field.value}
        ref={ref}
        {...createProps}
      />
    </>
  )
})

export default AsyncSelect
